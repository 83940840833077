.leaflet-popup-content {
    h3,
    p {
        margin: 0.3em 0;
        line-height: 1.1;
    }

    .session.location.name {
        font-size: 18px;
    }

    .session.title {
        font-size: 18px;
    }

    .session.host {
        font-size: 12px;
    }
}
