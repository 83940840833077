.zww-day-selector {
    justify-content: space-evenly;
    padding: 10px;

    & > * {
        width: calc(100% / 9);
        text-align: center;
        white-space: nowrap;

        font-size: 18px;
        font-weight: 500;

        line-height: 1.2em;
        padding: 0.2em;
        margin-bottom: 2rem;

        &:hover {
            color: #26bde2;
            background: none;
        }

        &.zww-selected {
            background: #26bde2;
            color: white;
        }
    }
}

@media only screen and (max-width: 960px) {
    .zww-day-selector .zww-weekday-long {
        display: none;
    }
}

@media only screen and (max-width: 479px) {
    .zww-day-selector > * {
        min-width: calc(100% / 5);
    }
}
