.zww-session-filter {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3rem;
    padding: 10px;

    h4 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 800;
    }

    ul {
        margin: 0 2rem 2rem 0;
        list-style-type: none;

        li {
            line-height: 1.2em;
            cursor: pointer;

            &:hover,
            &.zww-selected {
                color: #26bde2;
            }
        }
    }

    .zww-flex > * {
        min-width: calc(100% / 4);
    }

    .zww-session-filter-button-holder {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        button {
            flex: 1;
            padding: 0;
            font-size: 22px;
            text-align: left;
        }
    }
}

.zww-session-filter-open {
    color: #26bde2;
    background: none;
    border: none;

    i {
        width: 1.5em;
    }

    &:hover {
        background: none;
    }
}

@media only screen and (max-width: 960px) {
    .zww-session-filter .zww-flex > * {
        min-width: calc(100% / 2);
    }
}
