.zww-day-row {
    padding: 5rem 1rem 1rem;
    position: relative;

    span {
        background: #E5007C;
        padding: 0.2em 1em;
        color: #fff;
        font-weight: 800;
    }

    &:first-of-type {
        padding-top: 1rem;
        margin-top: 5rem;
    }

    &:first-of-type::before {
        display: none;
    }
}

.zww-flex {
    display: flex;
    flex-wrap: wrap;
}

.zww-list-item-3 {
    width: calc(100% / 3);
    padding: 1rem;
    margin-bottom: 5rem;
}

.zww-short-listing .zww-list-item-3:nth-of-type(4) {
    display: none;
}

.zww-excess-sessions {
    margin-top: -3rem;
    margin-bottom: 10rem;
    text-align: right;
    font-size: 18px;
    font-weight: 600;

    a {
        color: #26bde2;

        &:hover {
            color: #19486a;
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
    .zww-short-listing .zww-list-item-3:nth-of-type(4) {
        display: block;
    }

    // hide excess-sessions link if exactly 4 sessions, since in this case we show just those 4
    .zww-excess-sesions-4 {
        display: none;
    }
}

@media only screen and (max-width: 960px) {
    .zww-list-item-3 {
        width: 50%;
    }
}

@media only screen and (max-width: 479px) {
    .zww-list-item-3 {
        width: 100%;
        padding: 0;
    }
}

.zww-teaser-thumbnail {
    width: 100%;
}

.event-content {
    &:hover {
        h3,
        .zww-details-hint {
            color: #26bde2;
        }
    }

    .zww-time-location {
        color: #26bde2;
        font-size: 18px;
        margin: 1em 0;
        font-weight: 600;
        line-height: 1.2em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3 {
        color: #19486a;
        margin-bottom: 0.4em;
        font-weight: 800;
        font-size: 35px;
        line-height: 1.2em;
    }

    .zww-details-hint {
        color: #19486a;
        font-size: 18px;
        font-weight: 800;
    }
}
