.zww-sdg-box {
    margin-top: 8rem;
}

.zww-sdg-icon-list {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;

    li {
        display: block;
        padding: 0 1rem 0.2rem 0;
        padding-left: 0;
        width: 25%;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 479px) {
    .zww-sdg-icon-list li {
        width: 50%;
    }
}
