.zww-session-detail,
.zww-host-detail {
    font-size: 21px;
    line-height: 1.4em;
    font-weight: 400;

    strong {
        font-weight: 800;
    }

    h3 {
        font-weight: 700;
        font-size: 35px;
        margin-top: 3rem;
    }

    h4 {
        font-weight: 700;
        font-size: 35px;
        color: #fff;
    }

    .date-details {
        background: #E5007C;
        padding: 10px 40px;
        color: #fff;
        margin-top: 25px;
    }

    .event-details {
        background: #26bde2;
        padding: 10px 40px;
        color: #fff;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.zww-session-detail-left {
    width: calc(100% * 2 / 3);
    padding-right: 3rem;
}

.zww-session-detail-right {
    width: calc(100% / 3);
}

@media only screen and (max-width: 800px) {
    .zww-session-detail-left,
    .zww-session-detail-right {
        width: 100%;
        padding-right: 0;
    }
}

.zww-intro-image {
    max-width: 100%;
    max-height: 75vh;
}

.zww-intro-image {
    object-fit: cover;
    width: 100%;
}

.zww-host-image {
    max-height: 15rem;
}

@media only screen and (min-width: 961px) {
    .zww-host-image {
        float: right;
        margin-left: 4rem;
        max-width: 33%;
    }
}

.zww-link-box {
    font-size: 32px;

    i {
        margin-right: 0.5em;
    }

    .zww-host-detail & a:hover {
        color: #26bde2;
    }
}

.event-details {
    a {
        color: #fff;
    }

    a:hover {
        color: #19486a;
    }
}

.zww-sessions-of-host {
    font-weight: 700;
    font-size: 35px;
    margin-top: 10rem;
}

.zww-clearfix::after {
    content: '';
    clear: both;
    display: table;
}

.zww-back-button {
    font-family: 'Roboto', Sans-serif;
    font-size: 2.4rem;
    font-weight: 400;
    color: #fff;
    background-color: #E5007C;
    padding: 15px 40px 15px 40px;
    line-height: 1;
    border: none;
    margin-top: 5rem;

    &:hover {
        background-color: #16a772;
    }

    i {
        margin-right: 0.5em;
    }
}

.zww-teaser-wrapper {
    position: relative;

    .zww-session-cancelled {
        position: absolute;
        bottom: 3rem;
        width: 100%;
        background: red;
        color: white;
        font-weight: 600;
        padding: 0.5em;
        font-size: 1.8rem;
    }
}

h4.zww-session-cancelled {
    color: red;
}